import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { onChange } from 'reducers/campaignSlice';

const IdentitySelect = (props) => {
  const dispatch = useDispatch();
  const { fromEmail } = useSelector((state) => state.campaign);
  const { Item } = Form;
  const { Option } = Select;

  const { value, onSelect, identites } = props;

  const domainSelector = (
    <Item name='domain' noStyle>
      <Select
        value={value}
        onSelect={onSelect}
        placeholder='Selecione um domínio verificado'
      >
        {identites.map((item) => (
          <Option disabled={!item.active} key={item._id} value={item._id}>
            {`@${item.domain}`}
          </Option>
        ))}
      </Select>
    </Item>
  );

  return (
    <Item
      name='from'
      wrapperCol={{ span: 12 }}
      label='Remetente'
      rules={[{ required: true, message: 'Digite um endereço de e-mail' }]}
    >
      <Input
        addonAfter={domainSelector}
        value={fromEmail}
        onChange={(e) => dispatch(onChange({ key: 'fromEmail', value: e.target.value }))}
      />
    </Item>
  );

  // return (
  //   <Item
  //     name='from'
  //     wrapperCol={{ span: 12 }}
  //     label='Remetente'
  //     rules={[{ required: true, message: 'Escolha uma das opções' }]}
  //   >
  //     <Select value={value} onSelect={onSelect} placeholder='Selecione o remetente'>
  //       {identites.map((item) => (
  //         <Option disabled={!item.active} key={item._id} value={item._id}>
  //           {`${item.name} <${item.email}>`}
  //         </Option>
  //       ))}
  //     </Select>
  //   </Item>
  // );
};

IdentitySelect.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func,
  identites: PropTypes.array,
};

export default IdentitySelect;
