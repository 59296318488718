import React from 'react';
import { Result } from 'antd';
import { Link } from 'react-router-dom';

const Error404 = () => (
  <Result
    status='404'
    title='Não há nada aqui'
    subTitle='Você deve ter chegado a esta página por engano'
    extra={
      <Link className='ant-btn ant-btn-primary' to='/'>
        Ir para a home
      </Link>
    }
  />
);

export default Error404;
