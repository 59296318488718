import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Card, Row, Col } from 'antd';
import { activateTour, deactivateTour } from '../../reducers/authSlice';

import { SectionWrapper } from 'components/Wrappers';

const SettingsBoard = () => {
  const dispatch = useDispatch();

  const { tour } = useSelector((state) => state.auth);

  const [checkedTour, setCheckedTour] = useState(false);

  useEffect(() => {
    setCheckedTour(
      Object.values(tour).reduce(
        (result, value) => (result ? result : value),
        false
      )
    );
  }, [tour]);

  function onChange(checkedTour) {
    if (checkedTour === true) {
      return dispatch(activateTour());
    } else {
      return dispatch(deactivateTour());
    }
  }

  return (
    <SectionWrapper noBack title='Configurações' fullWidth>
      <div className='site-card-border-less-wrapper'>
        <Card>
          <h3>Configurações Gerais</h3>
          <Row id='step-11' gutter={16}>
            <Col span={8}>Inicializar tour de ajuda ao iniciar o Hermes</Col>
            <Col span={8} offset={8}>
              <Switch checked={checkedTour} onChange={onChange} />
            </Col>
          </Row>
        </Card>
      </div>
    </SectionWrapper>
  );
};

export default SettingsBoard;
