import React from 'react';
import PropTypes from 'prop-types';
import { PageHeader, Layout } from 'antd';
import { useHistory } from 'react-router-dom';

const SectionWrapper = ({ title, children, fullWidth, subTitle, extra, noBack }) => {
  const history = useHistory();

  return (
    <>
      <PageHeader
        title={title}
        subTitle={subTitle}
        extra={extra}
        onBack={() => history.goBack()}
        backIcon={noBack && false}
      />
      <Layout.Content style={{ margin: '0 24px', padding: fullWidth ? null : 20 }}>
        {children}
      </Layout.Content>
    </>
  );
};

SectionWrapper.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  extra: PropTypes.array,
  noBack: PropTypes.bool,
};

export default SectionWrapper;
