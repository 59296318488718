import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Card, Form } from 'antd';

import { StepOne, StepTwo, StepThree, StepFour } from 'components/CampaignForm';
import ButtonGroup from 'components/CampaignForm/ButtonGroup';
import CampaignSteps from 'components/CampaignSteps';

const NewCampaign = () => {
  const [form] = Form.useForm();
  const { current, entities } = useSelector((state) => state.steps);
  const { cardTitle } = entities[current];
  const content = [<StepOne />, <StepTwo />, <StepThree />, <StepFour />];

  return (
    <Row gutter={16} style={{ padding: 10 }}>
      <Col span={4}>
        <CampaignSteps current={current} />
      </Col>
      <Col span={18}>
        <Card title={cardTitle}>
          <Form form={form} layout='vertical'>
            {content[current]}
          </Form>
        </Card>
        <ButtonGroup form={form} />
      </Col>
    </Row>
  );
};

export default NewCampaign;
