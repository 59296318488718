import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';

import { resetPassword } from 'reducers/authSlice';

import { GradientWrapper } from 'components/Wrappers';

const ResetPassword = () => {
  const { Item } = Form;
  const { Password } = Input;
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useParams();

  const { loading } = useSelector((state) => state.auth);

  const submit = async (values) => {
    await dispatch(resetPassword({ ...values, token }));
    history.push('/login');
  };

  return (
    <GradientWrapper title='Recuperar senha' justify='center'>
      <p>Entre a sua nova senha abaixo</p>
      <Form style={{ padding: 30 }} onFinish={submit}>
        <Item
          hasFeedback
          name='password'
          rules={[{ required: true, message: 'Entre a sua senha' }]}
        >
          <Password prefix={<LockOutlined />} placeholder='Entre sua nova senha' />
        </Item>
        <Item
          name='confirmPassword'
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Por favor, confirme a sua senha',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('As duas senhas são diferentes');
              },
            }),
          ]}
        >
          <Password prefix={<LockOutlined />} placeholder='Confirme a entrada' />
        </Item>
        <Item>
          <Button
            loading={loading}
            type='primary'
            htmlType='submit'
            style={{ width: '100%' }}
          >
            Resetar senha
          </Button>
          <Item>
            <Button type='link' onClick={() => history.push('/login')}>
              Página inicial
            </Button>
          </Item>
        </Item>
      </Form>
    </GradientWrapper>
  );
};

export default ResetPassword;
