import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { Wrapper } from 'components/Wrappers';

const PrivateRoute = ({ children, ...rest }) => {
  const { signedIn } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={() => {
        if (signedIn) {
          return <Wrapper>{children}</Wrapper>;
        } else {
          return <Redirect to='/login' />;
        }
      }}
    />
  );
};

export default PrivateRoute;
