import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from 'routes/PrivateRoute';

import Error404 from 'pages/Error404';
import Unsubscribe from 'pages/Unsubscribe';
import ConfirmedAction from 'pages/ConfirmedAction';
import Home from 'pages/Home';
import { List, AudienceDetails } from 'pages/Contacts';
import { NewCampaign, CampaignList } from 'pages/Campaigns';
import { StatisticsMain } from 'pages/Statistics';
import { SettingsBoard } from 'pages/Settings';
import { Login, RecoverPassword, ResetPassword } from 'pages/Auth';

const MainRoutes = () => {
  return (
    <Switch>
      <Route path='/login'>
        <Login />
      </Route>
      <Route path='/recuperar-senha'>
        <RecoverPassword />
      </Route>
      <Route path='/password/reset/:token'>
        <ResetPassword />
      </Route>
      <Route path='/cancelar-subscricao/:token'>
        <Unsubscribe />
      </Route>
      <Route path='/obrigado'>
        <ConfirmedAction />
      </Route>
      <PrivateRoute exact path='/'>
        <Home />
      </PrivateRoute>
      <PrivateRoute exact path='/campanhas/nova'>
        <NewCampaign />
      </PrivateRoute>
      <PrivateRoute exact path='/campanhas'>
        <CampaignList />
      </PrivateRoute>
      <PrivateRoute exact path='/estatisticas'>
        <StatisticsMain />
      </PrivateRoute>
      <PrivateRoute exact path='/contatos'>
        <List />
      </PrivateRoute>
      <PrivateRoute exact path='/contatos/lista-transmissao/:name'>
        <AudienceDetails />
      </PrivateRoute>
      <PrivateRoute exact path='/configuracoes'>
        <SettingsBoard />
      </PrivateRoute>
      <PrivateRoute path='*'>
        <Error404 />
      </PrivateRoute>
    </Switch>
  );
};

export default MainRoutes;
