import React, { useState } from 'react';
import { Button } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import ContactModal from './ContactModal';

const AddContactModal = () => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <Button type='primary' onClick={() => setModal(true)}>
        <PlusOutlined />
        Adicionar Contato
      </Button>
      <ContactModal modal={modal} setModal={setModal} title='Adicionar Contato' />
    </>
  );
};

export default AddContactModal;
