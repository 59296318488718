import { Form, Select } from 'antd'
import React from 'react'


const TemplateSelect = (props) => {
  const { Item } = Form
  const { Option } = Select
  const { value, onSelect, templates } = props

  return (
    <Item
      name='template'
      label="Template"
      wrapperCol={{ span: 12 }}
    >
      <Select
        value={value}
        onSelect={onSelect}
        placeholder='Selecione um template'
      >
        {templates.map((item) => (
          <Option
            disabled={!item.active}
            key={item._id}
            value={item.value}>
            {item.description}
          </Option>
        ))}
      </Select>
    </Item>
  )
}
export default TemplateSelect
