import React from 'react';
import {
  HomeOutlined,
  SendOutlined,
  PieChartOutlined,
  TeamOutlined,
  SettingOutlined,
  QuestionOutlined,
} from '@ant-design/icons';

export default ({ id, children }) => {
  let icon;

  switch (id) {
    case 1:
      icon = <HomeOutlined />;
      break;

    case 2:
      icon = <SendOutlined />;
      break;

    case 3:
      icon = <PieChartOutlined />;
      break;

    case 4:
      icon = <TeamOutlined />;
      break;

    case 5:
      icon = <SettingOutlined />;
      break;

    default:
      icon = <QuestionOutlined />;
      break;
  }

  return (
    <span>
      {icon}
      <span>{children}</span>
    </span>
  );
};
