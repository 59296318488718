import { message } from 'antd';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';

const pending = (state) => {
  state.loading = true;
};

const rejected = (state, action) => {
  state.loading = false;
  message.error(action.payload);
};

export const fetchAudiences = createAsyncThunk(
  'audiences/fetchAudiencesStatus',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get('/audience');
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  loading: false,
  entities: [],
  selected: [],
};

const audiencesSlice = createSlice({
  name: 'audiences',
  initialState,
  reducers: {
    selectAudience(state, action) {
      state.selected.push(action.payload);
    },
    deselectAudience(state, action) {
      const index = state.selected.findIndex((value) => value === action.payload);
      state.selected.splice(index, 1);
    },
    resetAudiences(state) {
      state.selected = initialState.selected;
    },
  },
  extraReducers: {
    [fetchAudiences.fulfilled]: (state, action) => {
      state.loading = false;
      state.entities = action.payload;
    },
    [fetchAudiences.pending]: pending,
    [fetchAudiences.rejected]: rejected,
  },
});

export const {
  selectAudience,
  deselectAudience,
  resetAudiences,
} = audiencesSlice.actions;

export default audiencesSlice.reducer;
