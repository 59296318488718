import { message } from 'antd';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api, { auth, topic, user, TOKEN_KEY } from 'api';
import { loadData } from 'utils';

const pending = (state) => {
  state.loading = true;
};

const rejected = (state, action) => {
  state.loading = false;
  message.error(action.payload);
};

const fulfilled = (state, action) => {
  state.loading = false;
  message.success(action.payload.message);
};

export const fetchUser = createAsyncThunk(
  'user/fetchUserStatus',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await auth.get('/api/user/me');
      return data;
    } catch (error) {
      dispatch(signOut());
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const signIn = createAsyncThunk(
  'auth/signInStatus',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await auth.post('/signin', values);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const recoverPassword = createAsyncThunk(
  'auth/recoverPasswordStatus',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await auth.post('/recovery', values);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPasswordStatus',
  async (values, { rejectWithValue }) => {
    try {
      const { password, token, confirmPassword } = values;
      const { data } = await auth.post(`/reset/${token}`, {
        password,
        confirmPassword,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const changeCompany = createAsyncThunk(
  'auth/changeCompanyStatus',
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const { userId, company } = values;
      const { data } = await user.put(`/${userId}/company/change/${company._id}`);
      loadData(dispatch);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const unsubscribe = createAsyncThunk(
  'auth/unsubscribeStatus',
  async (values, { rejectWithValue }) => {
    try {
      const { token } = values;
      const { data } = await topic.post(`/unsubscribe/${token}`, values);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchTour = createAsyncThunk(
  'user/tourStatus',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.get('user/tour');
      return data;
    } catch (error) {
      dispatch(signOut());
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const activateTour = createAsyncThunk(
  'user/tour/activate',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.put('/user/tour/activate');
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deactivateTour = createAsyncThunk(
  'user/tour/deactivate',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.put('user/tour/deactivate');
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deactivateTourPages = createAsyncThunk(
  'user/tour/deactivate',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await api.put(`user/tour/deactivate/${values}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    signedIn: localStorage.getItem(TOKEN_KEY) !== null,
    user: undefined,
    tour: {
      home: false,
      campaign: false,
      contact: false,
      statistics: false,
    },
  },
  reducers: {
    signOut(state) {
      localStorage.clear();
      state.signedIn = false;
    },
  },
  extraReducers: {
    [fetchUser.pending]: pending,
    [fetchUser.rejected]: rejected,
    [fetchUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
    },
    [fetchTour.pending]: pending,
    [fetchTour.rejected]: rejected,
    [fetchTour.fulfilled]: (state, action) => {
      state.loading = false;
      state.tour = action.payload;
    },
    [resetPassword.pending]: pending,
    [resetPassword.rejected]: rejected,
    [resetPassword.fulfilled]: fulfilled,
    [recoverPassword.pending]: pending,
    [recoverPassword.rejected]: rejected,
    [recoverPassword.fulfilled]: fulfilled,
    [unsubscribe.pending]: pending,
    [unsubscribe.rejected]: rejected,
    [unsubscribe.fulfilled]: fulfilled,
    [changeCompany.pending]: pending,
    [changeCompany.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
    },
    [changeCompany.rejected]: rejected,
    [signIn.pending]: pending,
    [signIn.rejected]: rejected,
    [signIn.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.__v === 0) {
        message.info(action.payload.message);
      } else {
        localStorage.setItem(TOKEN_KEY, action.payload.token);
        state.signedIn = true;
      }
    },
    [activateTour.pending]: pending,
    [activateTour.rejected]: rejected,
    [activateTour.fulfilled]: (state, action) => {
      state.loading = false;
      state.tour = action.payload;
    },
    [deactivateTour.pending]: pending,
    [deactivateTour.rejected]: rejected,
    [deactivateTour.fulfilled]: (state, action) => {
      state.loading = false;
      state.tour = action.payload;
    },
    [deactivateTourPages.pending]: pending,
    [deactivateTourPages.rejected]: rejected,
    [deactivateTourPages.fulfilled]: (state, action) => {
      state.loading = false;
      state.tour = action.payload;
    },
  },
});

export const { signOut } = authSlice.actions;

export default authSlice.reducer;
