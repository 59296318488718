import React from 'react';
import Icon, {
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  CodeOutlined,
} from '@ant-design/icons';

export const INLINE_STYLES = [
  { label: <BoldOutlined />, style: 'BOLD', tip: 'Negrito' },
  { label: <ItalicOutlined />, style: 'ITALIC', tip: 'Itálico' },
  { label: <UnderlineOutlined />, style: 'UNDERLINE', tip: 'Sublinhado' },
];

export const TITLE_TYPES = [
  { label: 'H1', style: 'header-one', tip: 'Título Grande' },
  { label: 'H2', style: 'header-two', tip: 'Título Médio' },
  { label: 'H3', style: 'header-three', tip: 'Título Pequeno' },
  { label: 'H4', style: 'header-four', tip: 'Subtítulo Grande' },
  { label: 'H5', style: 'header-five', tip: 'Subtítulo Médio' },
  { label: 'H6', style: 'header-six', tip: 'Subtítulo Pequeno' },
];

export const BLOCK_TYPES = [
  {
    label: (
      <Icon
        component={() => (
          <svg width='1em' height='1em' fill='currentColor' viewBox='0 0 187.09 173.03'>
            <path d='M5.77,75.34V0H75.34V59.48q0,48.3-11.53,69.93Q48.66,158.24,15.86,173L0,147.79q19.83-8.29,29.2-24.69T39.65,75.34Zm111.75,0V0h69.57V59.48q0,48.3-11.54,69.93Q160.42,158.24,127.61,173l-15.86-25.24q19.83-8.29,29.2-24.69T151.4,75.34Z' />
          </svg>
        )}
      />
    ),
    style: 'blockquote',
    tip: 'Citação',
  },
  {
    label: <UnorderedListOutlined />,
    style: 'unordered-list-item',
    tip: 'Lista não-ordenada',
  },
  { label: <OrderedListOutlined />, style: 'ordered-list-item', tip: 'Lista numerada' },
  { label: <CodeOutlined />, style: 'code-block', tip: 'Bloco de código' },
];
