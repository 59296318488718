import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';

import { capitalize } from 'utils';

const AudienceSelect = (props) => {
  const { Item } = Form;
  const { Option } = Select;
  const { mode, value, onSelect, onDeselect, audiences, label } = props;

  return (
    <Item
      wrapperCol={{ span: 16 }}
      label={label}
      name='audiences'
      dependencies={['contacts']}
      rules={[{ required: true, message: 'Você deve escolher pelo menos uma lista' }]}
    >
      <Select
        showArrow
        mode={mode}
        value={value}
        onSelect={onSelect}
        onDeselect={onDeselect}
        placeholder='Selecione a sua lista aqui'
      >
        {audiences.map((item) => (
          <Option key={item.name} value={item.name}>
            {capitalize(item.name)}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

AudienceSelect.propTypes = {
  mode: PropTypes.string,
  value: PropTypes.array,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  audiences: PropTypes.array,
  label: PropTypes.string,
};

AudienceSelect.defaultProps = {
  mode: 'multiple',
  label: 'Selecione a Lista de transmissão',
};

export default AudienceSelect;
