export default [
  {
    id: 1,
    url: '/',
    text: 'Home',
  },
  {
    id: 2,
    url: '/campanhas',
    text: 'Campanhas',
  },
  {
    id: 3,
    url: '/estatisticas',
    text: 'Estatísticas',
  },
  {
    id: 4,
    url: '/contatos',
    text: 'Contatos',
  },
  {
    id: 5,
    url: '/configuracoes',
    text: 'Configurações',
  },
];
