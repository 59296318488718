import React from 'react';
import ReactJoyride from 'react-joyride';
import { useDispatch } from 'react-redux';
import { deactivateTourPages } from 'reducers/authSlice';

const Guide = (props) => {
  const dispatch = useDispatch();
  function handleEvent(event) {
    if (event.action === 'reset') {
      dispatch(deactivateTourPages(props.name));
    }
  }

  return (
    <ReactJoyride
      styles={{
        options: {
          primaryColor: '#fa8072',
        },
      }}
      run={props.run}
      callback={handleEvent}
      showSkipButton={true}
      showProgress={true}
      continuous={true}
      debug={true}
      locale={{
        back: 'Voltar',
        close: 'Fechar',
        last: 'Último',
        next: 'Próximo',
        skip: 'Pular',
      }}
      name={props.name}
      steps={props.steps}
    ></ReactJoyride>
  );
};

export default Guide;
