import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { signIn } from 'reducers/authSlice';
import { GradientWrapper } from 'components/Wrappers';

export default () => {
  const { Item } = Form;
  const [form] = Form.useForm();
  const { Password } = Input;
  const dispatch = useDispatch();
  const { signedIn, loading } = useSelector((state) => state.auth);
  const history = useHistory();

  useEffect(() => {
    if (signedIn) {
      history.push('/');
    }
  }, [signedIn, history]);

  const submit = async (values) => {
    const response = await dispatch(signIn(values));
    if (response.error) form.resetFields();
  };

  return (
    <GradientWrapper title='HERMES MAIL' hasPhoto>
      <Form
        style={{ padding: 30 }}
        initialValues={{ remember: true }}
        onFinish={submit}
        form={form}
      >
        <Item
          label='E-mail'
          hasFeedback
          name='email'
          rules={[{ required: true, message: 'Entre o seu e-mail' }]}
        >
          <Input prefix={<UserOutlined />} placeholder='E-mail' />
        </Item>
        <Item
          label='Senha'
          hasFeedback
          name='password'
          rules={[{ required: true, message: 'Entre a sua senha' }]}
        >
          <Password prefix={<LockOutlined />} placeholder='Senha' />
        </Item>

        <Item>
          <Item name='remember' valuePropName='checked' noStyle>
            <Checkbox>Lembrar de mim</Checkbox>
          </Item>
        </Item>
        <Item>
          <Button
            loading={loading}
            type='primary'
            htmlType='submit'
            style={{ width: '100%' }}
          >
            Entrar
          </Button>
        </Item>
        <Link to='/recuperar-senha'>Esqueci a minha senha</Link>
      </Form>
    </GradientWrapper>
  );
};
