import React from 'react';
import { Button, Row, Col, List } from 'antd';
import { RocketFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import ReactJoyride from '../components/GuideMap/Guide';
import stepsHome from '../components/GuideMap/stepsHome';

import HomeCard from 'components/HomeCard';
import { SectionWrapper } from 'components/Wrappers';
import mailImg from 'assets/img/mail.svg';

const Home = () => {
  const history = useHistory();
  const { loading, entities: campaigns } = useSelector((state) => state.campaign);
  const { home: runGuideHome } = useSelector((state) => state.auth.tour);

  return (
    <SectionWrapper>
      <ReactJoyride steps={stepsHome} run={runGuideHome} name={'home'}></ReactJoyride>
      <div id='step-2'>
        <Row gutter={[16, 16]}>
          <HomeCard span={24} color='#fa8072'>
            <Row gutter={16}>
              <Col style={{ textAlign: 'left' }} span={12}>
                <h1>Seja bem vindo ao Hermes!</h1>
                <p>
                  O Hermes é uma plataforma de criação, envio e rastreamento de campanhas
                  de e-mail. Marketing. Adicione contatos à listas de transmissão, crie
                  uma nova campanha e acompanhe os resultados!
                </p>
                <Button onClick={() => history.push('/ajuda')}>Saiba mais</Button>
              </Col>
              <Col span={8} offset={4}>
                <img src={mailImg} alt='Mail Immage' />
              </Col>
            </Row>
          </HomeCard>
        </Row>
        <Row gutter={[16, 16]}>
          <HomeCard span={8}>
            <RocketFilled style={{ fontSize: '8em', color: '#fa8072' }} />
            <h2>Nova campanha</h2>
            <Button
              id='step-3'
              size='large'
              type='primary'
              onClick={() => history.push('/campanhas/nova')}
            >
              Criar
            </Button>
          </HomeCard>
          <HomeCard
            loading={loading}
            id='step-4'
            span={16}
            title='Campanhas Recentes'
            extra={
              <Link id='step-5' to='/campanhas'>
                Ver tudo
              </Link>
            }
            full
          >
            <List
              loading={loading}
              dataSource={campaigns.slice(0, 3)}
              renderItem={(item) => {
                if (item.active)
                  return (
                    <List.Item
                      actions={[
                        <p>
                          {new Date(item.startDate).toLocaleString('pt-BR', {
                            dateStyle: 'short',
                            timeStyle: 'short',
                          })}
                        </p>,
                      ]}
                    >
                      <Link to={`/campanhas/${item._id}`}>{item.name}</Link>
                    </List.Item>
                  );
              }}
            />
          </HomeCard>
        </Row>
      </div>
    </SectionWrapper>
  );
};

export default Home;
