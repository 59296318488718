import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  content: null,
};

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    onSubmit(state, action) {
      state.content = action.payload;
    },
    resetEditor() {
      return initialState;
    },
  },
});

export const { onSubmit, resetEditor } = editorSlice.actions;

export default editorSlice.reducer;
