import { fetchCampaigns } from 'reducers/campaignSlice';
import { fetchAudiences } from 'reducers/audiencesSlice';
import { fetchContacts } from 'reducers/contactsSlice';
import { fetchIdentites } from 'reducers/identitiesSlice';

export default function loadData(dispatch) {
  dispatch(fetchCampaigns());
  dispatch(fetchContacts());
  dispatch(fetchIdentites());
  dispatch(fetchAudiences());
}
