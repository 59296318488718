import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

import { nextStep, previousStep } from 'reducers/stepsSlice';
import { postCampaign } from 'reducers/campaignSlice';
import { QuestionButton } from 'components/Buttons';
import { resetFields } from 'utils';
import HelpPopover from 'components/HelpPopover';

const ButtonGroup = (props) => {
  const { form } = props;
  const dispatch = useDispatch();
  const { current, entities } = useSelector((state) => state.steps);
  const { loading } = useSelector((state) => state.campaign);
  const history = useHistory();

  const onCancel = () => {
    resetFields(dispatch);
    history.push('/');
  };

  const next = async () => {
    try {
      await form.validateFields();
      dispatch(nextStep());
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async () => {
    await dispatch(postCampaign());
    history.push('/');
  };

  return (
    <div style={{ marginTop: 20 }}>
      <QuestionButton
        question='Tem certeza de que deseja descartar a campanha atual'
        action={onCancel}
      >
        Cancelar
      </QuestionButton>
      <div style={{ float: 'right' }}>
        {current > 0 && (
          <Button
            type='ghost'
            style={{ marginRight: 10 }}
            onClick={() => dispatch(previousStep())}
          >
            Voltar
          </Button>
        )}
        {current < entities.length - 1 && (
          <>
            <Button type='primary' onClick={next}>
              Próximo
            </Button>
            <HelpPopover message='Você deve completar todos os campos para ir para o próximo passo' />
          </>
        )}
        {current === entities.length - 1 && (
          <>
            <Button loading={loading} type='primary' onClick={onSubmit}>
              Enviar e-mail
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ButtonGroup;
