import React from 'react';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const QuestionButton = (props) => {
  const { children, question, action, ...rest } = props;

  const { confirm } = Modal;

  const confirmCancel = () => {
    confirm({
      title: question,
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirmar',
      okType: 'danger',
      onOk() {
        action();
      },
      cancelText: 'Cancelar',
    });
  };

  return (
    <Button {...rest} onClick={confirmCancel}>
      {children}
    </Button>
  );
};

QuestionButton.propTypes = {
  children: PropTypes.node,
  question: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

export default QuestionButton;
