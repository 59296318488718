import React from 'react';
import { useSelector } from 'react-redux';
import { Steps } from 'antd';

const CampaignSteps = () => {
  const { entities, current } = useSelector((state) => state.steps);
  const { Step } = Steps;

  return (
    <Steps size='small' direction='vertical' current={current}>
      {entities.map((item) => (
        <Step title={item.title} description={item.description} key={item.title} />
      ))}
    </Steps>
  );
};

export default CampaignSteps;
