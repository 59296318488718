import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from 'antd';

import { IdentitySelect, TemplateSelect } from 'components/Selects';
import { MainEditor } from 'components/RTEditor';
import { onChange } from 'reducers/campaignSlice';
import { selectIdentity } from 'reducers/identitiesSlice';
import { selectTemplate } from 'reducers/templatesSlice';

const StepThree = () => {
  const dispatch = useDispatch();
  const { subject } = useSelector((state) => state.campaign);
  const identities = useSelector((state) => state.identities);
  const templates = useSelector((state) => state.templates);
  const { Item } = Form;

  return (
    <>
      <IdentitySelect
        value={identities.selected._id}
        identites={identities.entities}
        onSelect={(value) => dispatch(selectIdentity(value))}
      />
      <Item
        hasFeedback
        name='subject'
        wrapperCol={{ span: 12 }}
        label='Assunto'
        rules={[{ required: true, message: 'Seu e-mail deve ter um assunto!' }]}
      >
        <Input
          value={subject}
          onChange={(e) => dispatch(onChange({ key: 'subject', value: e.target.value }))}
        />
      </Item>
      <TemplateSelect
        value={templates.selected._id}
        templates={templates.entities}
        onSelect={(value) => dispatch(selectTemplate(value))}
      />
      <Item label='Corpo do E-mail'>
        <MainEditor />
      </Item>
    </>
  );
};

export default StepThree;
