import React from 'react';
import { Tag } from 'antd';
import { Redirect } from 'react-router-dom';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import SearchFilter from 'components/TableFilters/SearchFilter';

import EditContactModal from 'components/CampaignForm/EditContactModal';

const Columns = (audiences) => {
  return [
    {
      title: 'Nome',
      className: 'step-14',
      key: 'name',
      render: (data) => {
        return <EditContactModal contact={data} />;
      },
      ...SearchFilter({ field: 'name' }),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      ...SearchFilter({ field: 'email' }),
    },
    {
      title: 'Lista de Transmissão',
      className: 'step-15',
      dataIndex: 'audiences',
      key: 'audiences',
      render: (audiences) =>
        audiences.map((item) => (
          <Tag
            onClick={() => <Redirect to={`/contatos/lista-transmissao/${item}`} />}
            key={item}
          >
            {item}
          </Tag>
        )),
      filters: audiences.map(({ name }) => {
        return { text: name, value: name };
      }),
      onFilter: (value, record) => {
        return record.audiences.includes(value);
      },
    },
    {
      title: 'Última modificação',
      dataIndex: 'lastUpdate',
      key: 'lastUpdate',
      render: (date) => new Date(date).toLocaleDateString('pt-BR', { dateStyle: 'long' }),
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',

      render: (active) =>
        active ? (
          <CheckCircleFilled style={{ color: '#52c41a' }} />
        ) : (
            <CloseCircleFilled style={{ color: '#f5222d' }} />
          ),
      filters: [
        {
          text: 'Ativo',
          value: true,
        },
        {
          text: 'Inativo',
          value: false,
        },
      ],
      onFilter: (value, record) => {
        return record.active === value;
      },
    },
  ];
};

export default Columns;
