import React from 'react';
import PropTypes from 'prop-types';

import StyleButton from './StyleButton';
import { INLINE_STYLES } from './styles';

const InlineStyleControls = (props) => {
  const { editorState, onToggle } = props;
  const currentStyle = editorState.getCurrentInlineStyle();

  return INLINE_STYLES.map((type) => (
    <StyleButton
      key={type.label}
      active={currentStyle.has(type.style)}
      label={type.label}
      onToggle={onToggle}
      style={type.style}
      tip={type.tip}
    />
  ));
};

InlineStyleControls.propTypes = {
  editorState: PropTypes.object,
  onToggle: PropTypes.func,
};

export default InlineStyleControls;
