import React, { useCallback, useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Card, DatePicker, Button } from 'antd';
import moment from 'moment';

import { SectionWrapper } from 'components/Wrappers';
import MetricCard from 'components/MetricCard';
import { CampaignSelect } from 'components/Selects';
import { fetchStatistics } from 'reducers/statisticsSlice';
import { filterCampaigns } from 'reducers/campaignSlice';

import ReactJoyride from 'components/GuideMap/Guide';
import stepsStatistics from 'components/GuideMap/stepsStatistics';


const StatisticsMain = () => {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;

  // Adicionado valor default para os ultimos 7 dias
  const defaultValue = [moment().subtract(6, 'days'), moment()];
  const [moments, setMoments] = useState(defaultValue.map((date) => date.toJSON()));

  const [selected, setSelected] = useState();

  const { loading, global, range } = useSelector((state) => state.statistics);
  const { filteredCampaigns: campaigns } = useSelector((state) => state.campaign);
  const { statistics: runGuideStatistics } = useSelector((state) => state.auth.tour);

  const getCampaignsData = useCallback(async () => {
    dispatch(filterCampaigns({ startDate: moments[0], endDate: moments[1] }))
  }, [dispatch, moments])

  const getStatisticsData = useCallback(() => {
    dispatch(
      fetchStatistics({
        obj: {
          startDate: moments[0],
          endDate: moments[1],
          campaign: selected,
        },
        interval: '/',
      })
    );
    //  
    dispatch(
      fetchStatistics({
        obj: {
          startDate: moments[0],
          endDate: moments[1],
          campaign: selected,
        },
        interval: '/daily',
        range: true,
      })
    );
  }, [selected, dispatch, moments]);



  const handleDateChange = (_, dates) => {
    setMoments(dates);
  };

  const handleCampaignChange = (value) => setSelected(value);

  // useEffect(() => getCampaignsData(), [getCampaignsData]);
  useEffect(() => {
    getStatisticsData();
    getCampaignsData();
  }, [moments, selected, getStatisticsData, getCampaignsData]);
  return (
    <>
      <ReactJoyride
        name={'statistics'}
        steps={stepsStatistics}
        run={runGuideStatistics}
      ></ReactJoyride>
      <SectionWrapper
        noBack
        title='Estatísticas'
        fullWidth
        extra={[
          <RangePicker
            key={1}
            style={{ margin: '0 20px' }}
            onChange={handleDateChange}
            defaultValue={defaultValue}
            ranges={{
              Hoje: [moment(), moment()],
              Ontem: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Ultimos 7 Dias': [moment().subtract(6, 'days'), moment()],
              'Ultimos 30 Dias': [moment().subtract(29, 'days'), moment()],
              'Este mês': [moment().startOf('month'), moment().endOf('month')],
              'Ultimo mês': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            }}
          />,
          <CampaignSelect
            key={2}
            value={selected}
            onChange={handleCampaignChange}
            campaigns={campaigns}
            allowClear
          />,

          <Button loading={loading} key={3} onClick={() => getStatisticsData()}>
            Buscar dados
          </Button>,
        ]}
      >
        <Row id='step-10' gutter={16}>
          <MetricCard
            loading={loading}
            title='Enviados'
            value={global.send}
            color='#52c41a'
          />
          <MetricCard
            loading={loading}
            title='Entregues'
            value={global.delivery}
            color='#52c41a'
          />
          <MetricCard loading={loading} title='Cliques' value={global.click} />
          <MetricCard loading={loading} title='Abertos' value={global.open} />
          <MetricCard loading={loading} title='Reabertura' value={global.reopen} />
          <MetricCard
            loading={loading}
            title='Rebatidos'
            value={global.bounce}
            color='#f5222d'
          />
          <MetricCard
            loading={loading}
            title='Notificados/Spam'
            value={global.complaint}
            color='#f5222d'
          />
          <MetricCard
            loading={loading}
            title='Cancelamentos'
            value={global.unsubscrible}
            color='#f5222d'
          />
        </Row>
        <Card id='step-11' style={{ marginTop: 30 }} title='Dados por campanha'>
          <ResponsiveContainer width='90%' height={300}>
            <LineChart data={range}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='date' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type='monotone' name='Enviados' dataKey='send' stroke='#8884d8' />
              <Line
                type='monotone'
                name='Entregues'
                dataKey='delivery'
                stroke='#82ca9d'
              />
              <Line type='monotone' name='Cliques' dataKey='click' stroke='#FA8C16' />
              <Line type='monotone' name='Abertos' dataKey='open' stroke='#31B2D5' />
            </LineChart>
          </ResponsiveContainer>
        </Card>
      </SectionWrapper>
    </>
  );
};

export default StatisticsMain;
