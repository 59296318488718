import React, { useState } from 'react';
import { Form, Modal, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AudienceSelect } from 'components/Selects';
import { addContact, editContact } from 'reducers/contactsSlice';
import { fetchAudiences } from 'reducers/audiencesSlice';

const ContactModal = (props) => {
  const { addSelected, contact, title, modal, setModal } = props;
  const dispatch = useDispatch();
  const audiences = useSelector((state) => state.audiences.entities);
  const { loading } = useSelector((state) => state.contacts);
  const [form] = Form.useForm();
  const { Item } = Form;

  const getFields = () => {
    const fieldsValues = Object.entries(contact).map((obj) => {
      return { name: obj[0], value: obj[1] };
    });
    return fieldsValues;
  }

  const [fields] = useState(contact ? getFields : [{
    name: '',
    email: '',
    audiences: []
  }]);

  const onFinish = async (values) => {
    if (contact) {
      await dispatch(editContact({ ...contact, ...values }));
    }
    else {
      await dispatch(addContact({ values, addSelected }));
      await dispatch(fetchAudiences());
      form.resetFields();
    }
    props.setModal(false);
  };

  return (
    <Modal
      title={title}
      onOk={() => form.submit()}
      visible={modal}
      onCancel={() => setModal(false)}
      confirmLoading={loading}
    >
      <Form form={form} onFinish={onFinish} fields={fields}>
        <Item label='Nome' name='name'>
          <Input type='text' />
        </Item>
        <Item label='E-mail' name='email'>
          <Input type='email' />
        </Item>
        <AudienceSelect
          mode='tags'
          audiences={audiences}
          label='Adicione à uma ou mais listas: '
        />
      </Form>
    </Modal>
  );
};

export default ContactModal;
