import React from 'react';

export default [
  {
    disableBeacon: true,
    target: '#step-1',
    content: <div>Utilize o menu para navegar pelo Hermes!</div>,
  },
  {
    target: '#step-2',
    content: (
      <div>
        Este é o painel inicial do Hermes!
        <br />
        Aqui é possível iniciar novas campanhas além de acompanhar os status de
        campanhas recentes.
      </div>
    ),
  },
  {
    target: '#step-3',
    content: <div>Para iniciar uma nova campanha, clique aqui!</div>,
  },
  {
    target: '#step-4',
    content: (
      <div>Encontre aqui de maneira rápida suas campanhas mais recentes.</div>
    ),
  },
  {
    target: '#step-5',
    content: (
      <div>E caso queira ver as demais campanhas criadas, clique aqui!</div>
    ),
  },
  {
    target: '#step-6',
    content: (
      <div>
        Utilize este menu para alterar a empresa, visualizar o usuário logado e
        sair do sistema.
      </div>
    ),
  },
];
