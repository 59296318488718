import React, { useState, useEffect } from 'react';
import { Card, Button } from 'antd';
import { ExportOutlined, EditOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import { SectionWrapper } from 'components/Wrappers';
import Error404 from 'pages/Error404';
import { capitalize } from 'utils';
import api from 'api';

const AudienceDetails = () => {
  const [contacts, setContacts] = useState();
  const { name } = useParams();

  useEffect(() => {
    const fetchAudience = async () => {
      try {
        const { data } = await api.get(`/audience/${name}`);
        setContacts(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAudience();
  }, [name]);

  if (!name) return <Error404 />;

  if (!contacts) return <div>Loading...</div>;

  return (
    <SectionWrapper
      fullWidth
      title={capitalize(name)}
      extra={[
        <Button key={2}>
          <ExportOutlined /> Exportar
        </Button>,
        <Button key={1}>
          <EditOutlined /> Editar
        </Button>,
      ]}
    >
      <Card title='Detalhes da Lista de Transmissão'>
        <dl>
          <dt>Total de Contatos</dt>
          <dd>{contacts.length}</dd>
        </dl>
      </Card>
      <Card title='Contatos'></Card>
    </SectionWrapper>
  );
};

export default AudienceDetails;
