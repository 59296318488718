import React from 'react';
import { Form, Select } from 'antd';
import PropTypes from 'prop-types';

import HelpPopover from 'components/HelpPopover';

const ContactSelect = (props) => {
  const { Item } = Form;
  const { Option } = Select;
  const { value, onSelect, onDeselect, contacts } = props;

  const onFilter = (input, option) => {
    input = input.toLowerCase();
    return option.children.toLowerCase().includes(input);
  };

  return (
    <Item
      wrapperCol={{ span: 16 }}
      label={
        <>
          Contatos adicionais
          <HelpPopover message='Você só pode enviar e-mails para contatos cadastrados' />
        </>
      }
    >
      <Select
        showArrow
        mode='multiple'
        value={value}
        placeholder='Selecione contatos adicionais'
        onSelect={onSelect}
        onDeselect={onDeselect}
        filterOption={onFilter}
      >
        {contacts.map((item) => (
          <Option disabled={!item.active} key={item._id} value={item._id}>
            {`${item.name} <${item.email}>`}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

ContactSelect.propTypes = {
  value: PropTypes.array,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  contacts: PropTypes.array,
};

export default ContactSelect;
