import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const CampaignSelect = (props) => {
  const { Option } = Select;
  const { value, allowClear, onChange, campaigns } = props;

  return (
    <Select
      allowClear={allowClear}
      value={value}
      onChange={onChange}
      placeholder='Selecione uma campanha'
    >
      {campaigns.map((item) => (
        <Option key={item._id} value={item._id}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
};

CampaignSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  campaigns: PropTypes.array,
};

export default CampaignSelect;
