import React from 'react';
import { Button, Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const HelpPopover = (props) => {
  const { message } = props;

  return (
    <Popover content={message}>
      <Button type='link'>
        <QuestionCircleOutlined />
      </Button>
    </Popover>
  );
};

HelpPopover.propTypes = {
  message: PropTypes.string.isRequired,
};

export default HelpPopover;
