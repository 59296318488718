import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table, Button, List } from 'antd';
import { SyncOutlined, NotificationOutlined } from '@ant-design/icons';

import { SectionWrapper } from 'components/Wrappers';
import { fetchCampaigns } from 'reducers/campaignSlice';
import { resetFields } from 'utils';
import columns from './columns';

import ReactJoyride from '../../components/GuideMap/Guide';
import stepsCampaign from '../../components/GuideMap/stepsCampaign';

const CampaignList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const audiences = useSelector((state) => state.audiences.entities);
  const { loading, entities: campaigns } = useSelector((state) => state.campaign);

  const { campaign: runGuideCampaign } = useSelector((state) => state.auth.tour);

  const newCampaign = () => {
    resetFields(dispatch);
    history.push('/campanhas/nova');
  };

  return (
    <>
      <ReactJoyride
        name={'campaign'}
        steps={stepsCampaign}
        run={runGuideCampaign}
        placement={'auto'}
      ></ReactJoyride>
      <div id='step-7'>
        <SectionWrapper
          noBack
          title='Campanhas'
          extra={[
            <Button key={1} onClick={() => dispatch(fetchCampaigns())}>
              <SyncOutlined spin={loading} />
            </Button>,
            <Button key={2} onClick={newCampaign} type='primary'>
              <NotificationOutlined />
              Nova Campanha
            </Button>,
          ]}
          fullWidth
        >
          <Table
            rowKey='_id'
            columns={columns.mainTable(audiences)}
            expandable={{
              rowExpandable: (record) => record.to.length > 0,
              expandedRowRender: (record) => (
                <List
                  header={<h4>Contatos</h4>}
                  dataSource={record.to}
                  renderItem={(item) => <p>{`${item.name} <${item.email}>`}</p>}
                />
              ),
            }}
            dataSource={campaigns}
            loading={loading}
          />
        </SectionWrapper>
      </div>
    </>
  );
};

export default CampaignList;
