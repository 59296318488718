import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button, Row } from 'antd';
import { SyncOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';

import {
  fetchContacts,
  activateContact,
  deactivateContact,
} from 'reducers/contactsSlice';
import { SectionWrapper } from 'components/Wrappers';
import AddContactModal from 'components/CampaignForm/AddContactModal';
import { QuestionButton } from 'components/Buttons';
import columns from './columns';
import HelpPopover from 'components/HelpPopover';

import ReactJoyride from '../../components/GuideMap/Guide';
import stepsContacts from '../../components/GuideMap/stepsContacts';

const Contacts = () => {
  const dispatch = useDispatch();
  const [selectedRows, setSelected] = useState([]);
  const { loading, entities: contacts } = useSelector((state) => state.contacts);

  const { contact: runGuideContact } = useSelector((state) => state.auth.tour);

  const audiences = useSelector((state) => state.audiences.entities);

  return (
    <>
      <ReactJoyride
        steps={stepsContacts}
        run={runGuideContact}
        name={'contact'}
      ></ReactJoyride>
      <div id='step-12'>
        <SectionWrapper
          noBack
          title='Contatos'
          extra={[
            <HelpPopover
              key={3}
              message='Utilize os filtros desejados para visualizar contatos dentro de uma lista de transmissão'
            />,
            <QuestionButton
              key={4}
              action={() => {
                const selected = [...selectedRows];
                while (selected.length) {
                  dispatch(activateContact({ ...selected.shift() }));
                }
              }}
              question='Os contatos selecionados serão ativados. Tem certeza de que deseja continuar?'
            >
              <CheckOutlined />
            </QuestionButton>,
            <QuestionButton
              type='danger'
              key={5}
              action={() => {
                const selected = [...selectedRows];
                while (selected.length) {
                  dispatch(deactivateContact({ ...selected.shift() }));
                }
              }}
              question='Os contatos selecionados serão desativados. Tem certeza de que deseja continuar?'
            >
              <DeleteOutlined />
            </QuestionButton>,
            <Button key={2} onClick={() => dispatch(fetchContacts())}>
              <SyncOutlined spin={loading} />
            </Button>,
            <AddContactModal key={1} />,
            <Row id='step-13' />
          ]}
          fullWidth
        >
          <Table
            loading={loading}
            rowKey='name'
            rowSelection={{
              type: 'checkbox',
              onChange: (_, rows) => {
                setSelected(rows);
              },
            }}
            columns={columns(audiences)}
            dataSource={contacts}
            pagination={{
              position: ['bottomCenter'],
              showTotal: (total) => `Total de ${total} itens`,
            }}
          />
        </SectionWrapper>
      </div>
    </>
  );
};

export default Contacts;
