import Axios from 'axios';
export const TOKEN_KEY = 'TOKEN_KEY';

const tokenConfig = async (config) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const auth = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL || 'https://api.surgeonline.com.br'}/auth`,
});

const hermes = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL || 'https://api.surgeonline.com.br'}/hermes/v1`,
});

const topic = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL || 'https://api.surgeonline.com.br'}/hermes/topic`,
});

const user = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL || 'https://api.surgeonline.com.br'}/auth/v1/user`,
});

hermes.interceptors.request.use(tokenConfig);
auth.interceptors.request.use(tokenConfig);
topic.interceptors.request.use(tokenConfig);
user.interceptors.request.use(tokenConfig);

export { auth, topic, user };

export default hermes;
