import bg from 'assets/img/hermes-bg.webp';

export default {
  container: {
    minHeight: '100vh',
    textAlign: 'center',
    justifyContent: 'center',
    background: 'transparent',
  },
  content: {
    background: '#fff',
    padding: 20,
    margin: 60,
    borderRadius: 10,
    boxShadow:
      'rgba(67, 90, 111, 0.3) 0px 0px 1px, rgba(67, 90, 111, 0.47) 0px 2px 4px -2px',
  },
  logo: {
    width: '20%',
    margin: 40,
  },
  title: {
    fontWeight: '100',
    color: 'grey',
  },
  background: (hasPhoto) =>
    hasPhoto
      ? {
          background: `linear-gradient(151deg,
      rgba(250,128,114, 0.9),
      rgba(255,195,113, 0.7)
    ), url(${bg}) no-repeat center center fixed`,
          backgroundSize: 'cover',
        }
      : {
          background:
            'linear-gradient(151deg, rgba(250,128,114,1) 50%, rgba(255,195,113,1) 100%)',
        },
};
