import { message } from 'antd';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

import { resetFields } from 'utils';

const pending = (state) => {
  state.loading = true;
};

const rejected = (state, action) => {
  state.loading = false;
  message.error(action.payload);
};

const fulfilled = (state, action) => {
  state.loading = false;
  message.success('Campanha criada com sucesso');
  state.entities.unshift(action.payload);
};

export const postCampaign = createAsyncThunk(
  'campaigns/postCampaignStatus',
  async (_, thunkAPI) => {
    try {
      const {
        campaign: { name, description, subject, fromEmail, startDate },
        audiences,
        contacts,
        identities,
        templates,
        editor: { content },
      } = thunkAPI.getState();

      const { data } = await api.post('/campaign', {
        name,
        description,
        startDate,
        subject,
        content,
        template: templates.selected,
        audiences: audiences.selected,
        from: {
          ...identities.selected,
          email: `${fromEmail}@${identities.selected.domain}`,
        },
        to: contacts.selected,
      });
      resetFields(thunkAPI.dispatch);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchCampaigns = createAsyncThunk(
  'campaigns/fetchCampaignsStatus',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get('/campaign');
      return data;
    } catch (error) {
    }
  }
);

export const filterCampaigns = createAsyncThunk(
  'campaigns/filterCampaignsStatus',
  async (values, { rejectWithValue }) => {
    try {
      let { startDate, endDate, campaign } = values;
      const { data } = await api.get('/campaign', {
        params: {
          startDate,
          endDate,
          campaign
        }
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const initialState = {
  name: '',
  description: '',
  subject: '',
  template: {},
  loading: false,
  entities: [],
  filteredCampaigns: [],
  fromEmail: '',
  startDate: null,
  endDate: null
};

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    addArticle(state, action) {
      state.articles.push(action.payload);
    },
    removeArticle(state, action) {
      state.articles.splice(action.payload, 1);
    },
    onChange(state, action) {
      const { key, value } = action.payload;
      state[key] = value;
    },
    resetCampaign(state) {
      state.name = '';
      state.description = '';
      state.subject = '';
      state.loading = false;
    },
  },
  extraReducers: {
    [postCampaign.pending]: pending,
    [postCampaign.rejected]: rejected,
    [postCampaign.fulfilled]: fulfilled,
    [fetchCampaigns.pending]: pending,
    [fetchCampaigns.rejected]: rejected,
    [fetchCampaigns.fulfilled]: (state, action) => {
      state.loading = false;
      state.entities = action.payload;
    },
    [filterCampaigns.pending]: pending,
    [filterCampaigns.rejected]: rejected,
    [filterCampaigns.fulfilled]: (state, action) => {
      state.loading = false;
      state.filteredCampaigns = action.payload;
    }
  },
});

export const {
  addArticle,
  removeArticle,
  onChange,
  resetCampaign,
} = campaignSlice.actions;

export default campaignSlice.reducer;
