import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, DatePicker } from 'antd';

import { onChange } from 'reducers/campaignSlice';
import moment from 'moment';

const StepOne = () => {
  const dispatch = useDispatch();
  const { name, description, startDate } = useSelector((state) => state.campaign);
  const { TextArea } = Input;
  const { Item } = Form;

  const rules = {
    name: [
      { required: true, message: 'O nome da campanha é obrigatório' },
      { min: 5, message: 'O nome da campanha é muito curto!' },
      { max: 40, message: 'O nome da campanha é muito grande!' },
    ],
    description: [{ max: 240, message: 'A sua descrição deve ter até 240 caracteres' }],
    startDate: [{ required: true, message: 'Informe a data e hora de início da campanha' }]
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const handleDisabledDate = current => {
    const dateFormat = 'YYYY-MM-DD'
    const beforeToday = current.format(dateFormat) < moment().format(dateFormat)
    return beforeToday
  }

  const handleDisabledTime = current => {
    const dateFormat = 'YYYY-MM-DD'
    const now = moment()
    if ((!current) || (current.format(dateFormat) !== now.format(dateFormat))) {
      return true
    }

    return {
      disabledHours: () => range(0, now.hours()),
      disabledMinutes: () =>
        current.hours() === now.hours() ?
          range(0, current.minutes()) :
          null,
    };
  }

  return (
    <>
      <Item
        hasFeedback
        rules={rules.name}
        name='name'
        wrapperCol={{ span: 16 }}
        label='Nome da Campanha'
      >
        <Input
          placeholder='Insira o nome da sua campanha'
          value={name}
          onChange={(e) => dispatch(onChange({ key: 'name', value: e.target.value }))}
        />
      </Item>
      <Item
        rules={rules.description}
        name='description'
        wrapperCol={{ span: 16 }}
        label='Descrição da Campanha'
      >
        <TextArea
          maxLength='300'
          placeholder='Descreva brevemente a usa campanha'
          onChange={(e) =>
            dispatch(onChange({ key: 'description', value: e.target.value }))
          }
          value={description}
        />
      </Item>
      <Item
        rules={rules.startDate}
        wrapperCol={{ span: 16 }}
        label='Início da Campanha'
      >
        <DatePicker
          disabledDate={handleDisabledDate}
          disabledTime={handleDisabledTime}
          showTime={{ format: 'HH:mm' }}
          format={'DD/MM/YYYY HH:mm'}
          onChange={(value) =>
            dispatch(onChange({ key: 'startDate', value: value ? value.toJSON() : null }))}
          defaultValue={moment()}
          value={startDate ? moment(startDate) : moment()}
        />
      </Item>
    </>
  );
};

export default StepOne;
