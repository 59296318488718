import { resetCampaign } from 'reducers/campaignSlice';
import { resetSteps } from 'reducers/stepsSlice';
import { resetAudiences } from 'reducers/audiencesSlice';
import { resetContacts } from 'reducers/contactsSlice';
import { resetIdentity } from 'reducers/identitiesSlice';
import { resetEditor } from 'reducers/editorSlice';

export default function resetFields(dispatch) {
  dispatch(resetContacts());
  dispatch(resetAudiences());
  dispatch(resetSteps());
  dispatch(resetCampaign());
  dispatch(resetIdentity());
  dispatch(resetEditor());
}
