import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { GradientWrapper } from 'components/Wrappers';
import { recoverPassword } from 'reducers/authSlice';

const RecoverPassword = () => {
  const { Item } = Form;
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const submit = async (values) => {
    const response = await dispatch(recoverPassword(values));
    if (!response.error) history.push('/login');
  };

  return (
    <GradientWrapper title='HERMES MAIL' hasPhoto>
      <p>
        Entre o seu e-mail no formulário abaixo e enviaremos um link para recuperação de
        senha
      </p>
      <Form style={{ padding: 30 }} onFinish={submit}>
        <Item
          hasFeedback
          name='email'
          rules={[{ required: true, message: 'Entre o seu e-mail' }]}
        >
          <Input prefix={<UserOutlined />} placeholder='E-mail' />
        </Item>
        <Item>
          <Button
            loading={loading}
            type='primary'
            htmlType='submit'
            style={{ width: '100%' }}
          >
            Recuperar senha
          </Button>
          <Item>
            <Button type='link' onClick={() => history.goBack()}>
              Voltar
            </Button>
          </Item>
        </Item>
      </Form>
    </GradientWrapper>
  );
};

export default RecoverPassword;
