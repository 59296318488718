import React, { useState, useRef, useEffect } from 'react';
import { Editor, EditorState, RichUtils, getDefaultKeyBinding } from 'draft-js';
import { useDispatch, useSelector } from 'react-redux';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import 'draft-js/dist/Draft.css';
import './styles.css';

import { onSubmit } from 'reducers/editorSlice';
import BlockStyleControls from './BlockStyleControls';
import InlineStyleControls from './InlineStyleControls';
import TitleLevelControl from './TitleLevelControl';

const MainEditor = () => {
  const dispatch = useDispatch();
  const editorRef = useRef('editor');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const rawState = useSelector((state) => state.editor.content);
  const useMountEffect = (fun) => useEffect(fun, []);

  const focus = () => editorRef.current.focus();

  const _onChange = (editorState) => setEditorState(editorState);

  const _handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      _onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const _mapKeyToEditorCommand = (event) => {
    if (event.keycode === 9) {
      const newEditorState = RichUtils.onTab(event, editorState, 4);
      if (newEditorState !== editorState) {
        _onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(event);
  };

  const _toggleBlockType = (blockType) => {
    _onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const _toggleInlineStyle = (inlineStyle) => {
    _onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
  };

  const getBlockStyle = (block) => {
    switch (block.getType()) {
      case 'blockquote':
        return 'RichEditor-blockquote';
      default:
        return null;
    }
  };

  let className = 'RichEditor-editor';
  const contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  useEffect(() => {
    dispatch(onSubmit(stateToHTML(editorState.getCurrentContent())));
  }, [dispatch, editorState]);

  useMountEffect(() => {
    if (rawState) {
      setEditorState(EditorState.createWithContent(stateFromHTML(rawState)));
    }
  });

  return (
    <>
      <div className='RichEditor-root'>
        <div className='RichEditor-controls'>
          <InlineStyleControls editorState={editorState} onToggle={_toggleInlineStyle} />
          <TitleLevelControl editorState={editorState} onToggle={_toggleBlockType} />
          <BlockStyleControls editorState={editorState} onToggle={_toggleBlockType} />
        </div>
        <div className={className} onClick={focus}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={_handleKeyCommand}
            keyBindingFn={_mapKeyToEditorCommand}
            onChange={_onChange}
            placeholder='Escreva seu texto aqui...'
            ref={editorRef}
            spellCheck={true}
          />
        </div>
      </div>
    </>
  );
};

export default MainEditor;
