import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { unsubscribe } from 'reducers/authSlice';

import { GradientWrapper } from 'components/Wrappers';

const Unsubscribe = () => {
  const { Item } = Form;
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useParams();
  const { TextArea } = Input;

  const { loading } = useSelector((state) => state.auth);

  const submit = async (values) => {
    await dispatch(unsubscribe({ ...values, token }));
    history.push('/obrigado');
  };

  return (
    <GradientWrapper title='Cancelar Recebimento' justify='center'>
      <p>
        Nós sentimos muito em ver você partir!
        <br />
        Por favor, informe abaixo porque você não quer receber mais mensagens nossas, para
        que possamos melhorar nossos serviços.
      </p>
      <Form style={{ padding: 30 }} onFinish={submit}>
        <Item>
          <TextArea rows={4} />
        </Item>
        <Item>
          <Button
            loading={loading}
            type='primary'
            htmlType='submit'
            style={{ width: '100%' }}
          >
            Enviar feedback
          </Button>
        </Item>
      </Form>
    </GradientWrapper>
  );
};

export default Unsubscribe;
