import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { EditorState, Editor } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { Row, Col, List, Divider, Table } from 'antd';

import { capitalize } from 'utils';

const StepFour = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { name, description, subject, fromEmail } = useSelector(
    (state) => state.campaign
  );
  const from = useSelector((state) => state.identities.selected);
  const contacts = useSelector((state) => state.contacts);
  const audiences = useSelector((state) => state.audiences.selected);
  const rawState = useSelector((state) => state.editor.content);
  const template = useSelector((state) => state.templates.selected);

  useEffect(() => {
    if (rawState) {
      setEditorState(EditorState.createWithContent(stateFromHTML(rawState)));
    }
  }, [rawState]);

  return (
    <>
      <Row>
        <Col span={12}>
          <dl>
            <dt>Campanha</dt>
            <dd>{name}</dd>
            <dt>Descrição</dt>
            <dd>{description}</dd>
          </dl>
        </Col>
        <Col span={12}>
          <dl>
            <dt>Assunto</dt>
            <dd>{subject}</dd>
            <dt>Remetente</dt>
            <dd>{`${from.name} <${fromEmail}@${from.domain}>`}</dd>
          </dl>
        </Col>
      </Row>
      {audiences.length > 0 && (
        <>
          <Divider />
          <dl>
            <dt>Listas de Transmissão</dt>
            <List
              size='small'
              dataSource={audiences}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta title={capitalize(item)} />
                </List.Item>
              )}
            />
          </dl>
        </>
      )}
      <Divider />
      <dl>
        <dt>Template</dt>
        <dd>{template.description}</dd>
      </dl>
      {contacts.selected.length > 0 && (
        <>
          <Divider />
          <dl>
            <dt style={{ marginBottom: 5 }}>Destinatários</dt>
            <Table
              pagination={{ pageSize: 5 }}
              columns={[
                { title: 'Nome', dataIndex: 'name', key: 'name' },
                { title: 'E-mail', dataIndex: 'email', key: 'email' },
              ]}
              dataSource={contacts.selected}
              size='small'
              rowKey='_id'
            />
          </dl>
        </>
      )}
      <Divider />
      <h3>Corpo do E-mail</h3>
      <Editor editorState={editorState} readOnly={true} textAlignment='center' />
    </>
  );
};

export default StepFour;
