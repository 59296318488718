import React from 'react';

export default [
  {
    disableBeacon: true,
    target: '#step-12',
    content: <div>Encontre aqui seus contatos adicionados.</div>,
  },
  {
    target: '#step-13',
    content: <div>Ative, desative ou atualize seus contatos! </div>,
  },
  {
    target: '.step-14',
    content: (
      <div>
        Utilize os icones de busca ou seleção ao lado do título para facilitar
        sua pesquisa.
      </div>
    ),
  },
  {
    target: '.step-15',
    content: (
      <div>
        Utilize o icone de filtro ao lado do título para facilitar sua busca.
      </div>
    ),
  },
];
