import { message } from 'antd';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';

const pending = (state) => {
  state.loading = true;
};

const rejected = (state, action) => {
  state.loading = false;
  message.error(action.payload);
};

const updated = (state, action) => {
  message.success('Contato atualizado com sucesso');
  //
  const { data } = action.payload;
  let index = state.entities.findIndex((obj) => obj._id === data._id);
  state.entities[index] = data;
  state.loading = false;
};

export const fetchContacts = createAsyncThunk(
  'contacts/fetchContacts',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get('/contact');
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addContact = createAsyncThunk(
  'contacts/addContactStatus',
  async (obj, { rejectWithValue }) => {
    const { values, addSelected } = obj;
    try {
      const { data } = await api.post('/contact/', values);
      return { data, addSelected };
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const editContact = createAsyncThunk(
  'contacts/edtContactStatus',
  async (obj, { rejectWithValue }) => {
    try {
      const contact = { ...obj };
      const { data } = await api.put(`/contact/${contact._id}`, contact);
      return { data };
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const activateContact = createAsyncThunk(
  'contacts/activateContactStatus',
  async (obj, { rejectWithValue }) => {
    try {
      const contact = { ...obj };
      const { data } = await api.put(`/contact/${contact._id}/activate`, {});
      return { data };
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deactivateContact = createAsyncThunk(
  'contacts/deactivateContactStatus',
  async (obj, { rejectWithValue }) => {
    try {
      const contact = { ...obj };
      const { data } = await api.put(`/contact/${contact._id}/deactivate`, {});
      return { data };
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const initialState = {
  loading: false,
  entities: [],
  selected: [],
};

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    selectContacts(state, action) {
      const selected = state.entities.find((obj) => obj._id === action.payload);
      state.selected.push(selected);
    },
    deselectContacts(state, action) {
      const index = state.selected.findIndex((value) => value === action.payload);
      state.selected.splice(index, 1);
    },
    resetContacts(state) {
      state.selected = initialState.selected;
    },
  },
  extraReducers: {
    [fetchContacts.pending]: pending,
    [fetchContacts.rejected]: rejected,
    [fetchContacts.fulfilled]: (state, action) => {
      state.loading = false;
      state.entities = action.payload;
    },
    [addContact.pending]: pending,
    [addContact.rejected]: rejected,
    [addContact.fulfilled]: (state, action) => {
      message.success('Contato criado com sucesso');
      state.loading = false;
      state.entities.push(action.payload.data);
      if (action.payload.addSelected) state.selected.push(action.payload.data);
    },
    [editContact.pending]: pending,
    [editContact.rejected]: rejected,
    [editContact.fulfilled]: updated,
    [deactivateContact.pending]: pending,
    [deactivateContact.rejected]: rejected,
    [deactivateContact.fulfilled]: updated,
    [activateContact.pending]: pending,
    [activateContact.rejected]: rejected,
    [activateContact.fulfilled]: updated,
  },
});

export const { selectContacts, deselectContacts, resetContacts } = contactsSlice.actions;
export default contactsSlice.reducer;
