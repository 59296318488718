import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';

import Header from 'components/Header';
import Sidebar from 'components/Sidebar';

const Wrapper = (props) => {
  const { children } = props;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Layout>
        <Header />
        <Layout.Content style={{ margin: 20 }}>{children}</Layout.Content>
      </Layout>
    </Layout>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node,
};

export default Wrapper;
