import React from 'react';
import PropTypes from 'prop-types';
import { Col, Card, Layout } from 'antd';

const HomeCard = (props) => {
  const { loading, span, children, color, full } = props;
  return (
    <Col span={span}>
      <Card {...props} loading={loading} style={{ background: color }}>
        <Layout
          style={{
            background: 'transparent',
            color: color && '#ffffff',
            textAlign: 'center',
            padding: full ? 0 : 20,
          }}
        >
          {children}
        </Layout>
      </Card>
    </Col>
  );
};

HomeCard.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  span: PropTypes.number,
  color: PropTypes.string,
  full: PropTypes.bool,
};

export default HomeCard;
