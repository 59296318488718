import { message } from 'antd';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';

const pending = (state) => {
  state.loading = true;
};

const rejected = (state, action) => {
  state.loading = false;
  message.error(action.payload);
};

export const fetchIdentites = createAsyncThunk(
  'identities/fetchIdentitesStatus',
  async () => {
    const { data } = await api.get('/identity');
    return data;
  }
);

const initialState = {
  loading: false,
  entities: [],
  selected: {},
};

const identitiesSlice = createSlice({
  name: 'identities',
  initialState,
  reducers: {
    selectIdentity(state, action) {
      const selected = state.entities.find((obj) => obj._id === action.payload);
      state.selected = selected;
    },
    resetIdentity(state) {
      state.selected = initialState.selected;
    },
  },
  extraReducers: {
    [fetchIdentites.fulfilled]: (state, action) => {
      state.loading = false;
      state.entities = action.payload;
    },
    [fetchIdentites.pending]: pending,
    [fetchIdentites.rejected]: rejected,
  },
});

export const { selectIdentity, resetIdentity } = identitiesSlice.actions;

export default identitiesSlice.reducer;
