import React from 'react';
import PropTypes from 'prop-types';

import StyleButton from './StyleButton';
import { BLOCK_TYPES } from './styles';

const BlockStyleControls = (props) => {
  const { editorState, onToggle } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return BLOCK_TYPES.map((type) => (
    <StyleButton
      key={type.label}
      active={type.style === blockType}
      label={type.label}
      onToggle={onToggle}
      style={type.style}
      tip={type.tip}
    />
  ));
};

BlockStyleControls.propTypes = {
  editorState: PropTypes.object,
  onToggle: PropTypes.func,
};

export default BlockStyleControls;
