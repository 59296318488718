import { configureStore } from '@reduxjs/toolkit';

import audiencesSlice from 'reducers/audiencesSlice';
import stepsSlice from 'reducers/stepsSlice';
import campaignSlice from 'reducers/campaignSlice';
import contactsSlice from 'reducers/contactsSlice';
import authSlice from 'reducers/authSlice';
import identitiesSlice from 'reducers/identitiesSlice';
import templatesSliece from 'reducers/templatesSlice';
import statisticsSlice from 'reducers/statisticsSlice';
import editorSlice from 'reducers/editorSlice';

export default configureStore({
  reducer: {
    audiences: audiencesSlice,
    steps: stepsSlice,
    campaign: campaignSlice,
    contacts: contactsSlice,
    auth: authSlice,
    identities: identitiesSlice,
    templates: templatesSliece,
    statistics: statisticsSlice,
    editor: editorSlice,
  },
});
