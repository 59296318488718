import React from 'react';
import { Form } from 'antd';
// import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { selectAudience, deselectAudience } from 'reducers/audiencesSlice';
import { selectContacts, deselectContacts } from 'reducers/contactsSlice';
import { AudienceSelect, ContactSelect } from 'components/Selects';
import { AddContactModal } from 'components/CampaignForm';

const StepTwo = () => {
  const dispatch = useDispatch();
  const audiences = useSelector((state) => state.audiences);
  const contacts = useSelector((state) => state.contacts);

  const { Item } = Form;

  return (
    <>
      <AudienceSelect
        mode='multiple'
        value={audiences.selected}
        onSelect={(value) => dispatch(selectAudience(value))}
        onDeselect={(value) => dispatch(deselectAudience(value))}
        audiences={audiences.entities}
      />
      {/* <Item>
        <Link className='ant-btn' to='/contatos'>
          Cadastrar/Editar Listas
        </Link>
      </Item> */}
      <ContactSelect
        value={contacts.selected.map((item) => item._id)}
        onSelect={(value) => dispatch(selectContacts(value))}
        onDeselect={(value) => dispatch(deselectContacts(value))}
        contacts={contacts.entities}
      />
      <Item>
        <AddContactModal addSelected />
      </Item>
    </>
  );
};

export default StepTwo;
