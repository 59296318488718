import React, { useState } from 'react';
import { Button } from 'antd';

import ContactModal from './ContactModal';

const EditContactModal = (props) => {
  const { contact } = props;
  const [modal, setModal] = useState(false);

  return (
    <>
      <Button type='link' onClick={() => setModal(true)}>
        {contact.name}
      </Button>
      <ContactModal
        modal={modal}
        setModal={setModal}
        contact={contact}
        title='Editar Contato'
      />
    </>
  );
};

export default EditContactModal;
