import { message } from 'antd';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

const pending = (state) => {
  state.loading = true;
};

const rejected = (state, action) => {
  state.loading = false;
  message.error(action.payload);
};

// const fulfilled = (state, action) => {
//   state.loading = false;
//   message.success(action.payload.message);
// };

const initialState = {
  loading: false,
  global: {},
  range: [],
};

export const fetchStatistics = createAsyncThunk(
  'statistics/fetchStatisticsStatus',
  async (values, { rejectWithValue }) => {
    const { obj, interval, range } = values;
    try {
      const { data } = await api.get(`/metrics/statistics${interval}`, { params: obj });
      return { data, range };
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  extraReducers: {
    [fetchStatistics.pending]: pending,
    [fetchStatistics.rejected]: rejected,
    [fetchStatistics.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.range) {
        state.range = action.payload.data;
      } else {
        state.global = action.payload.data;
      }
    },
  },
});

export default statisticsSlice.reducer;
