import React, { useState, useEffect } from 'react';
import { Menu, Layout } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import logo from 'assets/img/logo.svg';
import Icon from 'components/ItemIcon';
import links from './links';

export default () => {
  const [selected, setSelected] = useState();
  const location = useLocation();
  const { Item } = Menu;
  const { Sider } = Layout;

  useEffect(() => {
    setSelected('/' + location.pathname.split('/')[1]);
  }, [location]);

  const formattedLinks = links.map((item) => {
    return (
      <Item className={item.id} key={item.url}>
        <Link to={item.url}>
          <Icon id={item.id}>{item.text}</Icon>
        </Link>
      </Item>
    );
  });

  return (
    <Sider collapsible breakpoint='lg' theme='light'>
      <Menu id='step-1' mode='inline' selectedKeys={[selected]}>
        <Item style={{ margin: '12px 0' }}>
          <span>
            <span style={{ minWidth: '30px' }} role='img' className='anticon'>
              <img src={logo} alt='' />
            </span>
            <span>HERMES</span>
          </span>
        </Item>
        {formattedLinks}
      </Menu>
    </Sider>
  );
};
