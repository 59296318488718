import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  entities: [
    {
      title: 'Passo 1',
      cardTitle: 'Detalhes da Campanha',
      description: 'Detalhes',
    },
    {
      title: 'Passo 2',
      cardTitle: 'Selecionar destinatários',
      description: 'Destinatários',
    },
    {
      title: 'Passo 3',
      cardTitle: 'Criar conteúdo do e-mail',
      description: 'Conteúdo',
    },
    {
      title: 'Passo 4',
      cardTitle: 'Revisar dados e Enviar',
      description: 'Revisar',
    },
  ],
  current: 0,
};

const stepsSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    nextStep(state) {
      state.current++;
    },
    previousStep(state) {
      state.current--;
    },
    resetSteps() {
      return initialState;
    },
  },
});

export const { nextStep, previousStep, resetSteps } = stepsSlice.actions;

export default stepsSlice.reducer;
