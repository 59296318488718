import React from 'react';
import { Tag, Badge, Tooltip, Button } from 'antd';
import { Redirect } from 'react-router-dom';
import { capitalize } from 'utils';
import SearchFilter from 'components/TableFilters/SearchFilter';

const statusMap = {
  pending: {
    text: 'Pendente',
    badge: 'warning',
  },
  started: {
    text: 'Em progresso',
    badge: 'processing',
  },
  finished: {
    text: 'Finalizada',
    badge: 'success',
  },
  canceled: {
    text: 'Cancelada',
    badge: 'error',
  },
  erro: {
    text: 'Erro',
    badge: 'default',
  },
};

export default {
  mainTable: (audiences) => [
    {
      title: 'Campanha',
      className: 'step-8',
      dataIndex: 'name',
      key: 'name',
      render: (name) => <Button type='link'>{capitalize(name)}</Button>,
      ...SearchFilter({ field: 'name' }),
    },
    {
      title: 'Data de Início',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date) =>
        new Date(date).toLocaleString('pt-BR', {
          dateStyle: 'short',
          timeStyle: 'short',
        }),
    },
    {
      title: 'Remetente',
      dataIndex: 'from',
      id: 'step-11',
      key: 'from',
      render: (from) => (
        <Tooltip title={from.email}>
          <Button type='link'>{from.name}</Button>
        </Tooltip>
      ),
    },
    {
      title: 'Assunto do e-mail',
      dataIndex: 'subject',
      key: 'subject',
      ...SearchFilter({ field: 'subject' }),
    },
    {
      title: 'Lista de Transmissão',
      className: 'step-9',
      dataIndex: 'audiences',
      key: 'audiences',
      render: (audiences) =>
        audiences.map((item) => (
          <Tag
            onClick={() => (
              <Redirect to={`/contatos/lista-transmissao/${item}`} />
            )}
            key={item}
          >
            {item}
          </Tag>
        )),
      filters: audiences.map(({ name }) => {
        return { text: name, value: name };
      }),
      onFilter: (value, record) => {
        return record.audiences.includes(value);
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: Object.keys(statusMap)
        .filter((value) => value !== 'erro')
        .map((value) => {
          return { text: statusMap[value].text, value };
        }),
      onFilter: (value, record) => {
        return record.status === value;
      },
      render: (status) => {
        const { badge, text } = statusMap[status] || statusMap.erro;
        return <Badge status={badge} text={text} />;
      },
    },
  ],
};
