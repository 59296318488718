import React from 'react';
import { Layout, Button, Avatar, Dropdown, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LogoutOutlined, ShopOutlined, DownOutlined } from '@ant-design/icons';

import { signOut, changeCompany } from 'reducers/authSlice';
import { QuestionButton } from 'components/Buttons';

import ReactJoyride from 'components/GuideMap/Guide';
import stepsHome from 'components/GuideMap/stepsHome';

const Header = () => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.auth);

  const menu = (
    <Menu>
      {user &&
        user.companies.map((item) => (
          <Menu.Item
            key={item._id}
            onClick={() =>
              dispatch(changeCompany({ userId: user._id, company: item }))
            }
          >
            {item.name}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <React.Fragment>
      <div id='step-6'>
        <ReactJoyride step={stepsHome}></ReactJoyride>
        <Layout.Header style={{ background: '#fff' }}>
          <QuestionButton
            question='Você tem certeza de que deseja sair do sistema?'
            style={{ float: 'right' }}
            action={() => dispatch(signOut())}
            type='link'
            icon={<LogoutOutlined />}
          >
            Sair
          </QuestionButton>
          <Avatar style={{ float: 'right', background: '#fa8072' }}>
            {user && user.name.charAt(0)}
          </Avatar>
          <Button style={{ float: 'right' }} type='link'>
            {user && user.name}
          </Button>

          {loading ? (
            <Button style={{ float: 'left' }} type='ghost' loading={loading} />
          ) : (
            <Dropdown overlay={menu}>
              <Button style={{ float: 'left' }} type='ghost'>
                <ShopOutlined />
                {user && user.loggedAt.name}
                <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </Layout.Header>
      </div>
    </React.Fragment>
  );
};

export default Header;
