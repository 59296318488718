import React from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

const StyleButton = (props) => {
  const { onToggle, style, label, active, tip } = props;
  const _onToggle = (event) => {
    event.preventDefault();
    onToggle(style);
  };

  return (
    <Tooltip title={tip}>
      <span
        onMouseDown={_onToggle}
        className={`RichEditor-styleButton ${active && 'RichEditor-activeButton'}`}
      >
        {label}
      </span>
    </Tooltip>
  );
};

StyleButton.propTypes = {
  onToggle: PropTypes.func,
  label: PropTypes.string,
  active: PropTypes.bool,
  style: PropTypes.string,
  tip: PropTypes.string,
};

StyleButton.defaultProps = {
  tip: 'Texto descritivo',
};

export default StyleButton;
