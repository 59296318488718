import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigProvider, Spin } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';

import MainRoutes from 'routes/MainRoutes';
import { fetchCampaigns } from 'reducers/campaignSlice';
import { fetchAudiences } from 'reducers/audiencesSlice';
import { fetchContacts } from 'reducers/contactsSlice';
import { fetchIdentites } from 'reducers/identitiesSlice';
import { fetchTemplates } from 'reducers/templatesSlice';
import { fetchUser, fetchTour } from 'reducers/authSlice';

function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { signedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    const load = async () => {
      if (signedIn) {
        await dispatch(fetchUser());
        await dispatch(fetchTour());
        await dispatch(fetchCampaigns());
        setLoading(false);
        await dispatch(fetchAudiences());
        await dispatch(fetchContacts());
        await dispatch(fetchIdentites());
        await dispatch(fetchTemplates());
      }
    };
    load();
    setLoading(false);
  }, [signedIn, dispatch]);

  if (loading)
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size='large' style={{ display: 'flex' }} />
      </div>
    );

  return (
    <ConfigProvider locale={ptBR}>
      <MainRoutes />
    </ConfigProvider>
  );
}

export default App;
