import React from 'react';
import PropTypes from 'prop-types';
import { Col, Card, Statistic } from 'antd';

const MetricCard = (props) => {
  const { loading, title, value, color } = props;
  return (
    <Col span={6}>
      <Card loading={loading}>
        <Statistic title={title} value={value} valueStyle={{ color }} />
      </Card>
    </Col>
  );
};

MetricCard.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.number,
  color: PropTypes.string,
};

export default MetricCard;
