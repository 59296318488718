import React from 'react';
import { Popover } from 'antd';
import { FontSizeOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import StyleButton from './StyleButton';
import { TITLE_TYPES } from './styles';

const TitleLevelControl = (props) => {
  const { editorState, onToggle } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const content = TITLE_TYPES.map((type) => (
    <StyleButton
      key={type.label}
      active={type.style === blockType}
      label={type.label}
      onToggle={onToggle}
      style={type.style}
      tip={type.tip}
    />
  ));

  return (
    <Popover content={content}>
      <span className='RichEditor-styleButton'>
        <FontSizeOutlined />
      </span>
    </Popover>
  );
};

TitleLevelControl.propTypes = {
  editorState: PropTypes.object,
  onToggle: PropTypes.func,
};

export default TitleLevelControl;
