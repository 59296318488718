import { message } from 'antd';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';

const pending = (state) => {
  state.loading = true;
};

const rejected = (state, action) => {
  state.loading = false;
  message.error(action.payload);
};

export const fetchTemplates = createAsyncThunk(
  'templates/fetchTemplatesStatus',
  async () => {
    const { data } = await api.get('/mail_template');
    return data;
  }
);

const initialState = {
  loading: false,
  entities: [],
  selected: {},
};

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    selectTemplate(state, action) {
      const selected = state.entities.find((obj) => obj._id === action.payload);
      state.selected = selected;
    },
    resetTemplate(state) {
      state.selected = initialState.selected;
    },
  },
  extraReducers: {
    [fetchTemplates.fulfilled]: (state, action) => {
      state.loading = false;
      state.entities = action.payload;
    },
    [fetchTemplates.pending]: pending,
    [fetchTemplates.rejected]: rejected,
  },
});

export const { selectTemplate, resetTemplate } = templatesSlice.actions;

export default templatesSlice.reducer;
