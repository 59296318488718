import React from 'react';

export default [
  {
    disableBeacon: true,
    target: '#step-7',
    content: (
      <div>
        Encontre aqui informações mais completas sobre as campanhas já criadas!
      </div>
    ),
  },
  {
    target: '.step-8',
    content: (
      <div>
        Utilize o icone de busca ao lado do título para facilitar sua busca.
      </div>
    ),
  },
  {
    target: '.step-9',
    content: (
      <div>
        Utilize o icone de filtro ao lado do título para facilitar sua busca.
      </div>
    ),
  },
];
