import React from 'react';

export default [
  {
    disableBeacon: true,
    target: '#step-10',
    content: (
      <div>Encontre aqui estatisticas gerais das campanhas já criadas.</div>
    ),
  },
  {
    target: '#step-11',
    content: <div>Pesquise também informações por campanha especifica!</div>,
  },
];
